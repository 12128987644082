$black:#000;
$spans:#df212d;
$bg:darken($spans,15);
$white:#fff;
$title:#447bbf;
$text_dest:darken($spans,10);
$color_gradiant:#293983;
$cancelled:rgb(250, 61, 61);
$in_progress:rgb(14, 168, 14);
$pending:#0e90bb;
//gradientes
$btn:linear-gradient(to bottom, $color_gradiant 0%,$title 100%);
$btn_hover:linear-gradient(to bottom,$title  0%, $color_gradiant 100%);