.scroll_over {
    overflow-x: scroll;
}

.mw {
    min-width: 100%;
}

.btn_span {
    min-width: 100%;
    float: left;
    color: $black;
    text-align: center;

    i {
        font-size: 2rem;
    }
    button {
        color: $white!important;
        ;

        &:hover {
            color:lighten($spans, 10) !important;
            text-shadow: 0 0 5px darken($spans, 100), -1px -1px 1px darken($spans, 100),1px 1px 1px darken($spans, 100) !important;
        }
    }
}
@media only screen and (max-width: 600px) {
    .btn_span {
        min-width: 11.111%;
        float: left;
        color: $white;
        text-align: center;
    
        i {
            font-size: 2rem;
        }
    }
    .mw {
        min-width: 1280px;
    }
  }
  @media only screen and (max-width: 800px) {
    .btn_span {
        min-width: 11.111%;
        float: left;
        color: $white;
        text-align: center;
    
        i {
            font-size: 2rem;
        }
    }
    .mw {
        min-width: 1280px;
    }
  }